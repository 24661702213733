import React from "react";
import { downloadFile, deleteFile, reduceToShortText, setError } from "../../utils/utils";
import { AiOutlineEye } from "react-icons/ai";
import { MdOutlineCloudDownload } from "react-icons/md";
import { LiaTrashSolid } from "react-icons/lia";
import { PiRectangleFill } from "react-icons/pi";
import Popup from 'reactjs-popup';
import DangerModal from "../../utils/dangerModal";

export default function File({ file, project, files, setFiles }) {
    const isPdf = file.filename.toLowerCase().endsWith(".pdf");

    function formateDate(date) {
        // date comes from the backend like "2025-03-09T09:26:24.355626" it should be like "09.03.2025"
        return date.slice(8, 10) + "." + date.slice(5, 7) + "." + date.slice(0, 4);
    }

    const handleOpenPDF = async (fileName) => {
        try {
            const response = await downloadFile(project.id, file.id);
            
            const blob = await response.data;
            const url = URL.createObjectURL(blob);

            if (isPdf) {
                window.open(url, '_blank');
            }
            const link = document.createElement('a');
            link.href = url;
            link.download = 'document.pdf'; // Specify the filename with .pdf extension
            link.target = '_blank';
            link.click();
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    async function downloadDocx() {
        try {
            const response = await downloadFile(project.id, file.id);
            const blob = await response.data;
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'document.docx'; // Specify the filename with .docx extension
            link.target = '_blank';
            link.click();
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }
    

    const rectangleColor = {
        success: "green",
        failed: "red",
        progress: "blue",
        created: "gray",
        duplicated: "yellow"
    };

    const rectangleStyle = {
        color: rectangleColor[file.state],
    };

    const rectangleTitle = {
        success: "Erfolgreich hochgeladen",
        failed: "Hochladen fehlgeschlagen, versuche das Dokument in einem anderen Format hochzuladen",
        progress: "In Bearbeitung",
        created: "Erstellt, wartet auf Bearbeitung durch den Server",
        duplicated: "Ein Dokument mit gleichem Inhalt existiert bereits"
    };

    function removeFile() {
        try {
            deleteFile(project, file);
            setFiles(files.filter((f) => f.id !== file.id));
        } catch (error) {
            setError("Die Datei konnte nicht gelöscht werden.");
        }
    }


    return (
        <tr className="file">
            <th className="file__name">{reduceToShortText(file.filename, 200)}</th>
            <td className="file__date">{formateDate(file.created_at)}</td>
            <td className="file__status">{file.state} <PiRectangleFill style={rectangleStyle} title={rectangleTitle[file.state]}/> </td>
            <td className="file__actions">
                <Popup className="modal" trigger={<button className="file__delete-button"> <LiaTrashSolid /> </button>} modal>
                    {close =>
                        <DangerModal close={close} callback={removeFile} text={"Bist du sicher, dass du die Datei entfernen willst?"} title={"Datei löschen"}/>
                    }
                </Popup>
                {!isPdf && <button className="file__download-docx-button" onClick={downloadDocx}> <MdOutlineCloudDownload /> </button>}
                {isPdf &&  <button className="file__open-pdf-button" onClick={() => handleOpenPDF(file.filename)}> <AiOutlineEye /> </button>}
            </td>
        </tr>
    );
}