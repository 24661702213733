import './App.scss';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './components/login/login';
import { hasToken, clearCache, appName, getMe, removeToken } from './utils/utils';
import * as Sentry from "@sentry/react";
import Header from './components/header';
import Chat from './components/Chat/chat';
import HelpPage from './components/helpPage';
import DocumentsPage from './components/documentsPage';
import ContactPage from './components/contactPage';
import ErrorBanner from './utils/errorBanner';
import SuccessBanner from './utils/successBanner';
import FilesPage from './components/filesPage';
import TeamsPage from './components/team/teamsPage';
import PasswordResetForm from './components/passwortReset/passwordResetForm';
import NewPasswordForm from './components/passwortReset/newPasswordForm';

function App() {
  const [authenticated, setAuthenticated] = useState(hasToken() || false);
  const [role, setRole] = useState('');
  
  const logout = () => {
    setAuthenticated(false);
    clearCache();
    removeToken();
    window.location.href = window.location.origin;
  }
  useEffect(() => {
    if (authenticated && !role) {
      getMe().then(response => {
        if (response && response.status === 200) {
          setRole(response.data.role);
        }
      })
    }
  }, [authenticated]);

  return (
    <div className="App">
      <Sentry.ErrorBoundary fallback={<p>"An error has occurred"</p>}>
      <ErrorBanner />
      <SuccessBanner />
        {authenticated ?
        <Router>
        <div className='page-container'>
          <Header logout={logout} role={role} />
          <div className='page-content'>
            <div className='Chat-header'>{appName()}</div>
            <Routes>
              <Route path="/" element={<Chat authenticated={authenticated} logout={logout} role={role} setAuthenticated={setAuthenticated} />}/>
              <Route path="/chat" element={<Chat authenticated={authenticated} role={role} logout={logout} setAuthenticated={setAuthenticated} />}/>
              <Route path='/help' element={<HelpPage />} />
              <Route path='/documents' element={<DocumentsPage role={role} />} />
              <Route path='/mail' element={<ContactPage />} />
              {role && role === "admin" && <Route path='/team' element={<TeamsPage />} />}
              {role && role === "admin" && <Route path='/files' element={<FilesPage />} />}
            </Routes>
          </div>
          <div className='footer'>
            <a target="_blank" rel="noopener noreferrer" href='https://betriebsratsbot.ai/impressum/'>Impressum</a>
            <a target="_blank" rel="noopener noreferrer" href='https://betriebsratsbot.ai/datenschutz/'>Datenschutz</a>
          </div>
        </div>
        </Router> 
        : 
        <Router>
          <Routes>
            <Route path="/" element={<Login setAuthenticated={setAuthenticated} setRole={setRole} />}/>
            <Route path="/reset-password" element={<NewPasswordForm />} />
            <Route path="/request-password-reset" element={<PasswordResetForm/>}/>
          </Routes>
        </Router>
        }
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default App;
