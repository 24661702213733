import React from 'react';
import { HiOutlineChat } from 'react-icons/hi';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { LuHelpCircle } from 'react-icons/lu';
import { TbMailQuestion } from 'react-icons/tb';
import { HiOutlineLogout } from 'react-icons/hi';
import { IoFolderOpenOutline } from "react-icons/io5";
import { FiUserPlus } from "react-icons/fi";
import { Link } from 'react-router-dom';

function Header({logout, role}) {

    var iconSize = 45;
    var robotSize = 60;
    const dpr = window.devicePixelRatio;
    if (dpr > 1) {
        iconSize = iconSize / ((dpr - 1) * 0.4 + 1);
    }
    return (
        <div className="App-header">
            <Link to="/" className='navbar__item--leading'>
                    <img src='/robot-dark.png' width={robotSize} height={robotSize} alt="Robot" title='Home'/>
            </Link>
            <Link to="/chat" className='navbar__item'>
                <HiOutlineChat size={iconSize} stroke='white' title='Chat'/>
            </Link>
            <Link to="/documents" className='navbar__item'>
                <IoDocumentTextOutline size={iconSize} stroke='white' title='Dokumente'/>
            </Link>
            {role && role === 'admin' && <Link to="/files" className='navbar__item'>
                <IoFolderOpenOutline size={iconSize} stroke='white' title='Dateien'/>
            </Link>}
            {role && role === 'admin' && <Link to="/team" className='navbar__item'>
                <FiUserPlus size={iconSize} stroke='white' title='Team'/>
            </Link>}
            <Link to="/help" className='navbar__item'>
                <LuHelpCircle size={iconSize} stroke='white' title='Häufig gestellte Fragen'/>
            </Link>
            <Link to="/mail" className='navbar__item'>
                <TbMailQuestion size={iconSize} stroke='white' title='Kontakt'/>
            </Link>
            <HiOutlineLogout size={iconSize} className='navbar__item--last logout' stroke='white' onClick={logout} title='Logout'/>
        </div>
    );
}

export default Header;