import React from 'react';
import { useState, useEffect } from 'react';
import { resetPassword } from '../../utils/utils';
import { Link } from 'react-router-dom';
import LoginHeader from '../login/loginHeader';

export default function PasswordResetForm() {
    const [email, setEmail] = useState();
    const [success, setSuccess] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);

    const handleSubmit = async e => {
        e.preventDefault();
        setSuccess(false);
        const response = await resetPassword(email);
        if (response === undefined) {
            setSuccess(false);
        } else {
            setSuccess(true);
        }
        setEmail('');
        setRemainingTime(60);
    }

    useEffect(() => {
        if (remainingTime > 0) {
            setTimeout(() => {
                setRemainingTime(remainingTime - 1);
            }, 1000);
        }
    }, [remainingTime]);

    return(
        <div>
            <LoginHeader/>    
            <form className='Login'>
                    <div className='Login__forgot-password'>Passwort vergessen?</div>
                    <p className='Login__text'>Wenn du dein Passwort vergessen hast, kannst du es hier zurücksetzen. Gib deine Email ein und klicke auf "Passwort zurücksetzen". Wir senden dir dann eine Email mit einem Link zum Zurücksetzen des Passworts.</p>
                    <input placeholder='Email-Adresse' className="Login__input" type="text" onChange={e => setEmail(e.target.value)}/>
                    <Link to="/" className='Login__request-password-reset'>Zurück zum Login</Link>
                <div>
                    <button disabled={remainingTime > 0} title={remainingTime > 0 ? `Bitte warte noch ${remainingTime} Sekunden bis du es erneut versuchst` : ''} className={"Login__button" + (remainingTime > 0 ? " disabled" : "")} onClick={handleSubmit}>Passwort zurücksetzen</button>
                </div>
                {success && <p className='password-reset__success'>Wir haben eine Email mit einem Link zum Zurücksetzen des Passworts an deine Email geschickt, falls die Email bei uns existiert.</p>}
            </form>
        </div>
    )
}
