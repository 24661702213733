import React from "react";
import { useDropzone } from "react-dropzone";

const extensionToMimeType = {
  pdf: "application/pdf",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  doc: "application/msword",
};

function FileUploadDropZone({ handleFileUpload, fileTypes, table, children }) {
  const formattedAccept = fileTypes
    .map((ext) => extensionToMimeType[ext.toLowerCase()] || `.${ext.toLowerCase()}`)
    .reduce((acc, type) => {
      if (type.startsWith(".")) {
        acc["application/octet-stream"] = [...(acc["application/octet-stream"] || []), type];
      } else {
        acc[type] = acc[type] || [];
      }
      return acc;
    }, {});

  // render a simple input element with an onChange event listener that calls the handleFileUpload function
  const onDrop = (acceptedFiles) => {
    handleFileUpload(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true, // Disables click-based file selection
    accept: formattedAccept,
  });

  if (table) {
      return (
        <tbody className="filesPage__table"
        {...getRootProps()}
        
        >
        {children}
      </tbody>
    );
  } else {
      return (
        <div className="filesPage__dropzone"
        {...getRootProps()}
        >
        {children}
        <input {...getInputProps()} />
      </div>
    );
  }
};
export default FileUploadDropZone;