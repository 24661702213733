import React from 'react';
import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { getMembers, inviteMember } from '../../utils/utils';
import Member from './member';

export default function TeamsPage() {
    const [members, setMembers] = useState([]);
    const [email, setEmail] = useState(null);
    const role = "admin";

    useEffect(() => {
        fetchMembers();
    }, []);

    async function fetchMembers() {
        let user;
        const membersWithStatus = [];
        getMembers().then(members => {
            members.forEach(member => {
                user = member.user
                membersWithStatus.push({
                    email: user.email,
                    id: member.id,
                    created_at: member.created_at,
                    status: getMemberStatusFromDateFields(user),
                })
            })
            
            setMembers(sortMembers(membersWithStatus)); 
        })
    }

    function sortMembers(members) {
        return members.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }

    function getMemberStatusFromDateFields(member) {
        const created_at = new Date(member.created_at);
        const updated_at = new Date(member.updated_at);
        // a member is "Mitglied" if updated at ist at least 2 minutes after created at
        if (updated_at.getTime() - created_at.getTime() > 120000) {
            return "Mitglied";
        } else {
            return "Eingeladen";
        }
    }

    function invite() {
        if (email) {
            inviteMember(email, role).then(response =>{
                if (response) {
                    fetchMembers();
                }
            })
        }
    }

    return (
        <div className='teams-page'>
            <div className='teams-page__licenses-title'>Lizenzen</div>
            <div className='teams-page__licenses-box'>
                <div className='teams-page__licenses-info-text'>
                    Lade deine Betriebsratsmitglieder ein, die eine Lizenz zur Nutzung erhalten sollen.
                </div>
                <Popup className='modal' trigger={<button className='teams-page__invite-button'> Leute Einladen</button>} modal>
                    {close => <div className='invite-form'>
                        <div className='invite-form__title'>
                            Benutzer einladen
                        </div>
                        <input className='invite-form__email' onChange={(event)=>setEmail(event.target.value)} placeholder='E-Mail-Adresse'/>
                        <button className='invite-form__submit' onClick={()=>{
                            invite();
                            close();
                            }}>Einladen</button>
                    </div>}
                </Popup>
            </div>
            <div className='teams-page__members'>
                <table className='teams-page__members-table'>
                    <thead className='teams-page__members-table-head'>
                        <tr className='teams-page__members-table-head-row'>
                            <th className='teams-page__members-table-header'>E-Mail</th>
                            <th className='teams-page__members-table-header'>Status</th>
                        </tr>
                    </thead>
                    <tbody className='teams-page__members-table-body'>
                        {members.map((member) => {
                            return (
                                <Member key={member.id} member={member} fetchMembers={fetchMembers}/>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}