import React from 'react';
import { useState, useEffect } from 'react';
import { setNewPassword, setSuccess } from '../../utils/utils';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { FaEyeSlash, FaEye } from "react-icons/fa6";
import LoginHeader from '../login/loginHeader';

export default function NewPasswordForm() {
    const [passwordValue, setPasswordValue] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState();
    const [passwordConfirmationShow, setPasswordConfirmationShow] = useState(false);
    const [passwordWeak, setPasswordWeak] = useState();
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [timeoutId, setTimeoutId] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token');

    const navigate = useNavigate();

    const handleSubmit = async e => {
        e.preventDefault();
        const response = await setNewPassword(passwordValue, token);
        if (response === undefined) {
            return;
        }
        if (response.status === 201) {
            setTimeout(() => {
                setSuccess("Passwort erfolgreich geändert! Du kannst dich nun anmelden.");
                navigate('/');
            })
        }
    }

    
    function isPasswordStrongEnough(password) {
        var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
        if (!regularExpression.test(password)) {
            return false;
        }
        return true;
    }
    useEffect(() => {
    
        const checkPassword = () => {
            if (passwordValue && passwordValue.length > 0) {
                setPasswordWeak(!isPasswordStrongEnough(passwordValue));
            } else {
                setPasswordWeak(false);
            }
            if (passwordConfirmation && passwordConfirmation.length > 0) {
                setPasswordMatch(passwordValue === passwordConfirmation);
            } else {
                setPasswordMatch(true);
            }
        };
    
        if (passwordValue && passwordValue.length > 0) {
            // Clear any existing timeout
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            if (isPasswordStrongEnough(passwordValue)) {
                // Immediately update state if password is correct
                setPasswordWeak(false);
            } else {
                // Set a timeout for showing errors
                setTimeoutId(setTimeout(checkPassword, 500));
            }
        } else {
            setPasswordWeak(false);
        }
    
        if (passwordValue && passwordConfirmation && passwordValue.length > 0 && passwordConfirmation.length > 0) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            if (passwordValue === passwordConfirmation) {
                // Immediately update state if password is correct
                setPasswordMatch(true);
            } else {
                // Set a timeout for showing errors
                setTimeoutId(setTimeout(checkPassword, 500));
            }
        }
    
        // Cleanup function
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [passwordValue, passwordConfirmation]);
    
    const size = 25;

    return(
        <div>
            <LoginHeader/>
            <form className='Login'>
                    <div className='Login__new-password'>Neues Password eingeben</div>
                <div className='Login__password-wrapper'>
                    <input placeholder='Passwort' className="Login__input" type={showPassword ? "text" : "password"} onChange={e => setPasswordValue(e.target.value)}/>
                    <div className='Login__show-password' onClick={() => setShowPassword(!showPassword)}>{showPassword ? <FaEyeSlash size={size} /> : <FaEye size={size}/>}</div>
                </div>
                <div className='Login__password-wrapper'>
                    <input placeholder='Passwort bestätigen' className="Login__input" type={passwordConfirmationShow ? "text" : "password"} onChange={e => setPasswordConfirmation(e.target.value)}/>
                    <div className='Login__show-password' onClick={() => setPasswordConfirmationShow(!passwordConfirmationShow)}>{passwordConfirmationShow ? <FaEyeSlash size={size} /> : <FaEye size={size}/>}</div>
                </div>
                <Link to="/" className='Login__request-password-reset'>Zurück zum Login</Link>
                <div>
                    <button className="Login__button" onClick={handleSubmit}>Passwort festlegen</button>
                </div>
                {passwordWeak && <p className='invalid-token'>Das Passwort muss mindestens eine Zahl und ein Sonderzeichen enthalten und zwischen 8 und 16 Zeichen lang sein</p>}
                {!passwordWeak && !passwordMatch && <p className='invalid-token'>Die Passwörter stimmen nicht überein</p>}
            </form>
        </div>
    )
}
