import FileUploadDropZone from "./files/fileUploadDropZone";
import FileUploadButton from "./files/fileUploadWithSelection";
import DropdownMenu from "./dropDownMenu";
import * as Sentry from "@sentry/react";


import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";

import { useState, useEffect, useRef } from "react";
import { uploadFile, setError, getProjects, getFiles } from "../utils/utils";

import File from "./files/file";

const fileTypes = ["PDF", "DOCX", "DOC"];


const status = [
    {
        id: 1,
        name: "Fehler",
        value: "failed"
    },
    {
        id: 2,
        name: "in Bearbeitung",
        value: "processing"
    },
    {
        id: 3,
        name: "erfolgreich",
        value: "success"
    },
    {
        id: 4,
        name: "erstellt",
        value: "created"
    },
    {
        id: 5,
        name: "doppelt",
        value: "duplicated"
    },
    {
        id: 6,
        name: "Alle",
        value: ""
    }
]   

const size = 2;
const standardProject = { id: process.env.REACT_APP_PROJECT_ID, name: "Betriebratsbot" };


function FilesPage() {
    const [project, setProject] = useState(null);
    const [files, setFiles] = useState([]);
    const [search, setSearch] = useState("");
    const [state, setState] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [maxFiles, setMaxFiles] = useState(0);
    const [uploadCounter, setUploadCounter] = useState(0);
    const [timeoutId, setTimeoutId] = useState(null);
    const [chevronUp, setChevronUp] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);

    const scrollAbleDiv = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
          if (scrollAbleDiv.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollAbleDiv.current;
            if (scrollTop + clientHeight >= scrollHeight) {
              onScrollEnd();
            }
          }
        };
    
        const div = scrollAbleDiv.current;
        if (div) {
          div.addEventListener("scroll", handleScroll);
        }
        return () => {
          if (div) {
            div.removeEventListener("scroll", handleScroll);
          }
        };
    }, [files]);

    function onScrollEnd() {
        if (page <= totalPages) {
            clearTimeout(timeoutId);
            const newTimeoutId = setTimeout(() => {
                    appendFiles();
                }, 300);
                setTimeoutId(newTimeoutId);
        }
    }

    function appendFiles() {
        getFiles(project, page + 1, search, state).then((paginatedResponse) => {
            if (paginatedResponse.total_records) {
                setFiles([...files, ...paginatedResponse.items]);
                setMaxFiles(paginatedResponse.total_records);
                setTotalPages(paginatedResponse.total_pages);
            }
        });
        setPage(page + 1);
    }

    function handleSearchChange(event) {
        setSearchValue(event.target.value);
        clearTimeout(timeoutId);
        const newTimeoutId = setTimeout(() => {
            setSearch(event.target.value);
            setPage(1);
        }, 500);
        setTimeoutId(newTimeoutId);
    }

    useEffect(() => {
        getProjects().then((fetchedProjects) => {
                    let ownFiles = null;
                    for (let fetchedProject of fetchedProjects) {
                        if (!!fetchedProject.write_access) {
                            ownFiles = fetchedProject;
                        }
                    }
                    if (ownFiles) {
                        setProject({ id: ownFiles.id, name: "Eigene Dokumente" });
                    } else {
                        Sentry.captureException("No own Project:", { extra: { projects: fetchedProjects } });
                    }
                });
    }, []);

    useEffect(() => {
        clearTimeout(timeoutId);
        if (project) {
            getFiles(project, 1, search, state).then((paginatedResponse) => {
                if (paginatedResponse.total_records) {
                    setFiles(paginatedResponse.items);
                    setMaxFiles(paginatedResponse.total_records);
                    setTotalPages(paginatedResponse.total_pages);
                    // increase uploadcounter in 15 secs via timeout if any files are in created or processing state
                    if (paginatedResponse.items.some((file) => file.state === "created" || file.state === "processing")) {
                        setTimeoutId(setTimeout(() => {
                            setUploadCounter(uploadCounter + 1);
                        }, 15000));
                    }
                }
            });
        }
    }, [project, search, state, uploadCounter]);

    function handleClick(target) {
        setState(target.value);
        setPage(1);
        setChevronUp(false);
    }

    function select() {
        setChevronUp(!chevronUp)
    }


    const handleFileUpload = async (files) => {
        if (!project) {
            setError("Es konnte noch keine Verbindung zum Server hergestellt werden. Warte bitte ein paar Sekunden oder lade die Seite erneut.");
            return;
        }
        var error;
        if (files.length > 1) {
            var success = true;
            for (let i = 0; i < files.length; i++) {
                try {
                    const file = files[i];
                    if (fileTypes.every((type) => !file.name.toLowerCase().endsWith(type.toLowerCase()))) {
                        setError("Eine oder mehrere Dateien haben nicht den richtigen Dateityp, sie werden übersprungen.");
                        success = false;
                        continue;
                    }
                    await uploadFile(project, file).then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            setUploadCounter(uploadCounter + 1);
                        }
                    });
                } catch (e) {
                    error = e;
                    success = false;
                }
            }
        } else {
            const file = files[0];
            if (fileTypes.every((type) => !file.name.toLowerCase().endsWith(type.toLowerCase()))) {
                setError("Die Datei muss eine der folgenden Endungen haben: " + fileTypes.join(", "));
            } else {
                const response = await uploadFile(project, file);
                if (response.status >= 200 && response.status < 300){ 
                    setUploadCounter(uploadCounter + 1);
                }
            }
        }
    };

    return (
        <div className="filesPage">
            <div className="filesPage__title">Eigene Dateien</div>
            <div ref={scrollAbleDiv} className="filesPage__table-wrapper">
                <table className="filesPage__table">
                    <thead>
                        <tr className="filesPage__table-topheader">
                            <th className="filesPage__table-topheader-item">
                                <FileUploadButton handleFileUpload={handleFileUpload} fileTypes={fileTypes} />
                            </th>
                            <th className="filesPage__table-topheader-item"/>

                            <th className="filesPage__table-topheader-item">
                                <div className="fl">
                                    <DropdownMenu selectItem={handleClick} items={status}>
                                        <div className="filesPage__status" onClick={select}>{(state && state.length > 0) ? status.find((item) => item.value === state).name : "Status"} 
                                            {
                                                chevronUp ?
                                                    <IoChevronUpOutline size={size * 12}/>
                                                :
                                                <IoChevronDownOutline size={size * 12}/>
                                            }
                                        </div>
                                    </DropdownMenu>
                                </div>

                            </th>
                            <th className="filesPage__table-topheader-item--right">
                                <input type="text" value={searchValue} onChange={handleSearchChange} placeholder="Suche" className="filesPage__search" />
                            </th>
                        </tr>
                        <tr className="filesPage__table-header">
                            <th className="filesPage__table-header-item">Name</th>
                            <th className="filesPage__table-header-item filesPage__table-header-item--center">Upload-Datum</th>
                            <th className="filesPage__table-header-item filesPage__table-header-item--center">Status</th>
                            <th className="filesPage__table-header-item--right"></th>
                        </tr>
                    </thead>
                    { files && files.length > 0 && <FileUploadDropZone table={true} handleFileUpload={handleFileUpload} fileTypes={fileTypes}>
                                {files.map((file) => (
                                    <File key={file.id} file={file} project={project} setFiles={setFiles} files={files}/>
                                ))}
                    </FileUploadDropZone>}
                </table>
                {(!files || files.length === 0) &&  
                    <FileUploadDropZone handleFileUpload={handleFileUpload} fileTypes={fileTypes}>
                        <div className="filesPage__dropzone"></div>
                    </FileUploadDropZone>
                }
            </div>
        </div>
    );
}

export default FilesPage;