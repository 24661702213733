import React from "react";
import { useState, useEffect } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { LuInfo } from "react-icons/lu";
import { setToken, getMe, login, setError, clearError, removeToken } from "../../utils/utils";
import { FaEyeSlash, FaEye } from "react-icons/fa6";
import LoginHeader from "./loginHeader";

export default function Login({ setAuthenticated, setRole }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [email, setEmail] = useState('');
    const [passwordValue, setPasswordValue] = useState();
    const [showPassword, setShowPassword] = useState(false);
    
    const token = searchParams.get('token');

    useEffect(() => {
        if (token) {
          setToken(token);
          authenticateToken(token);
        }
      }, [token]);

    
      const handleSubmit = async e => {
        e.preventDefault();
        const response = await login(email, passwordValue);
        setPasswordValue("");
        if (response.status === 401 || response.status === 404) {
            setError("Das eingegebene Passwort oder die E-Mail-Adresse ist falsch.", 3000)
            return;
        }
        authenticateToken(response.data.access_token);
    }
    

    async function authenticateToken(authToken) {
        setToken(authToken);
        await getMe().then(response => {
            if (response && response.status === 200) {
                setAuthenticated(true);
                clearError();
                setRole(response.data.role);
                setSearchParams({});
            }
        }).catch(error => {
            removeToken();
            setError('Der Server ist nicht erreichbar, bitte versuchen Sie es später erneut.');
        })
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    }
    const size = 25;
    return (
        <div className='Login'>
            <LoginHeader/>
            <h2 className="Login__greeting--start">Moin,</h2>
            <h2 className="Login__greeting--end">Willkommen</h2>
            <input className='Login__input' type='text' placeholder="Email-Adresse" onKeyDown={handleKeyDown} onChange={(e) => setEmail(e.target.value)} />
            <div className='Login__password-wrapper'>
                <input placeholder='Passwort' className="Login__input" type={showPassword ? "text" : "password"} onChange={e => setPasswordValue(e.target.value)}/>
                <div className='Login__show-password' onClick={() => setShowPassword(!showPassword)}>{showPassword ? <FaEyeSlash size={size} /> : <FaEye size={size}/>}</div>
            </div>
            <Link to="/request-password-reset" className='Login__request-password-reset'>Passwort vergessen?</Link>
            <button className="Login__button" onClick={handleSubmit}>Anmelden</button>
            
            <div className='tooltip info-icon'>
                <LuInfo size={35} color='black' />
                <span className="tooltiptext"> Nach Abschluss eines Abonnements senden wir Ihnen einen Link zu, hinter dem Sie ihr Passwort setzen können.
                    Geht ihr Passwort verloren, können Sie es jederzeit wiederherstellen über "Passwort vergessen".
                </span>
            </div>
        </div>
    )
}
