import React from "react";

export default function DangerModal({close, callback, title, text}) {
    return (
        <div className='danger-modal'>
            <div className='danger-modal__title'>
                {title}
            </div>
            <div className='danger-modal__text'>
                {text}
            </div>
            <button className="danger-modal__cancel" onClick={close}>Abbrechen</button>
            <button className='danger-modal__submit' onClick={()=>{
                callback();
                close();
            }}>Löschen</button>
        </div>
    )
}