import React from "react";
import { useState } from "react";

export default function DropdownMenu(props) {
    const [open, setOpen] = useState(false);

    const handleOpen = (event) => {
        event.stopPropagation();
        
        if (open) {
            document.removeEventListener('click', handleClose);
        } else {
            document.addEventListener('click', handleClose);
        }
        setOpen(!open);
      };

    const handleClose = () => {
        setOpen(false);
        document.removeEventListener('click', handleClose);
    }

    return (
        <div className='items dropdown'>
            <div className="clickable" onClick={handleOpen}>
                {props.children}
            </div>
            {open && <ul className="menu">
                {props.items.map((item) => {
                    return (
                        <li className="menu-item" key={item.id}>
                            <button onClick={() => props.selectItem(item)}>{item.name}</button>
                        </li>
                    );
                })}
            </ul>}
        </div>
    );
}