import axios from "axios";
import Conversation from "../classes/conversation";
import MessageRequest from "../classes/messageRequest";

const appCacheCounter = "1"

export function appName() {
    return "BetriebsratsBot";
}

export function disclaimer() {
    return "Ich bin ein KI Bot und kann Fehler machen. Wichtige Informationen sollten überprüft werden! Dies ist keine Rechtsberatung."
}

export function hasToken() {
    const key = "token for " + process.env.REACT_APP_BACKEND_URL
    return localStorage.getItem(key) !== null;
}

export function token() {
    const key = "token for " + process.env.REACT_APP_BACKEND_URL
    return localStorage.getItem(key);
}

export function setToken(token) {
    const key = "token for " + process.env.REACT_APP_BACKEND_URL
    localStorage.setItem(key, token);
}

export function incrementRequestMadeToday() {
    const key = "request-made-today"
    const value = localStorage.getItem(key);
    const today = new Date().toDateString();
    let requests = null;
    if (value === null) {
        requests = {
            [today]: 1
        }
        localStorage.setItem(key, JSON.stringify(requests));
    } else {
        requests = JSON.parse(value);
        if (requests[today]) {
            requests[today]++;
        } else {
            requests = {
                [today]: 1
            }
        }
        localStorage.setItem(key, JSON.stringify(requests));
    }
}

export function getRequestsMadeToday() {
    const key = "request-made-today"
    const value = localStorage.getItem(key);
    if (value === null) {
        return 0;
    }
    const requests = JSON.parse(value);
    const today = new Date().toDateString();
    if (requests[today]) {
        return requests[today];
    }
    return 0;
}

export function removeToken() {
    const key = "token for " + process.env.REACT_APP_BACKEND_URL
    localStorage.removeItem(key);
}

export function cacheConversation(conversation) {
    localStorage.setItem("conversation cache - cache number:" + appCacheCounter, JSON.stringify(conversation));
}

export function clearConversation() {
    localStorage.removeItem("conversation cache - cache number:" + appCacheCounter);
}

export function getConversation() {
    const storageConversation = localStorage.getItem("conversation cache - cache number:" + appCacheCounter);
    if (storageConversation === null) {
        return null;
    }
    const conversation = JSON.parse(storageConversation);
    const newConversation = new Conversation(conversation.id, conversation.title);
    const messageRequests = [];
    for (const messageRequest of conversation.messageRequests) {
        const newMessageRequest = new MessageRequest(messageRequest.prompt, messageRequest.id, messageRequest.created_at);
        newMessageRequest.setReply(messageRequest.reply);
        messageRequests.push(newMessageRequest);
    }
    newConversation.setMessageRequests(messageRequests);
    return newConversation;
}

export function clearCache() {
    clearConversation();
    removeToken();
    clearConversation();
}

function parseConversation(fetchConversation) {
    const conversation = new Conversation(fetchConversation.id);
    const messageRequests = [];
    if (fetchConversation.title) {
        conversation.setTitle(fetchConversation.title);
    }
    if (fetchConversation.created_at) {
        conversation.setCreatedAt(fetchConversation.created_at);
    }
    if (!fetchConversation.message_requests) {
        return conversation;
    }
    for (const messageRequest of fetchConversation.message_requests) {
        const newMessageRequest = new MessageRequest(messageRequest.prompt, messageRequest.id, messageRequest.created_at);
        newMessageRequest.setReply(messageRequest.reply);
        messageRequests.push(newMessageRequest);
    }
    conversation.setMessageRequests(messageRequests);
    return conversation;
}

export async function login(email, password) {
    let response = null;
    try {
        response = await axios.post(process.env.REACT_APP_BACKEND_URL +"/api/v1/login", {email: email, password: password}, {
            headers: {
                "Content-Type": "application/json",
            },
        });
    } catch (error) {
        if (error.response) {
            response = error.response
        } else {
            handleError(error);
        }
    }
    return response;
}

export async function setNewPassword(password, token) {
    let response = null;
    try {
        response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/v1/reset_password", {password: password, token: token}, {
            headers: {
                "Content-Type": "application/json",
            },
        });
    } catch (error) {
        handleError(error);
    }
    return response;
}

export async function resetPassword(email) {
    let response = null;
    try {
        response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/v1/request_password_reset", {
            email: email,
            organization_id: process.env.REACT_APP_ORGANIZATION_ID
        }, {
            headers: {
                "Content-Type": "application/json",
            },
        });
    } catch (error) {
        handleError(error);
    }
    return response;
}


export async function getMe() {
    let response = null;
    try {
        response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/v1/members/me", {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
    } catch (error) {
        response = error.response;
        if (response.status === 401) {
            setError('Authentifizierung fehlgeschlagen, das angegebene Token ist nicht gültig.');
            removeToken();
        } else {
            setError(error.response.data.message);
        }
    }
    if (response.status === 401) {
        setError('Authentifizierung fehlgeschlagen, das angegebene Token ist nicht gültig.');
        removeToken();
    }
    if (!response) {
        setError('Der Server ist nicht erreichbar, bitte versuchen Sie es erneut.');
    }
    return response;
}

export async function getMembers() {
    let members = null;
    let response = null;
    try {
        response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/v1/members", {headers: {
            "Authorization": "Bearer " + token(),
        },});
    } catch (error) {
        handleError(error);
    }
    if (response.status === 200){
        members = response.data;
    } else if (response.status === 401) {
        setError('Authentifizierung fehlgeschlagen, das angegebene Token ist nicht gültig.');
        removeToken();
    }
    return members;
};

export async function deleteMember(member) {
    let response = null;
    try {
        response = await axios.delete(process.env.REACT_APP_BACKEND_URL + "/api/v1/members/" + member.id, {headers: {
            "Authorization": "Bearer " + token(),
        },});
    } catch (error) {
        response = error.response;
        if (response && response.status === 403) {
            setError("Du kannst weder dich selbst, noch das erste Mitglied deines Teams löschen.")
            return
        }
        handleError(error);
    }
    return response;
}


export async function inviteMember(email, role) {
    let response = null;
    try {
        response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/v1/invitations", {email: email, role: role}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token(),
            },
        });
    }
    catch (error) {
        handleError(error);
    }
    return response;
}


export async function getProjects() {
    let projects = null;
    let response = null;
    try {
        response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/v1/projects", {headers: {
            "Authorization": "Bearer " + token(),
        },});
    } catch (error) {
        handleError(error);
    }
    if (response.status === 200){
        projects = response.data;
    } else if (response.status === 401) {
        setError('Authentifizierung fehlgeschlagen, das angegebene Token ist nicht gültig.');
        removeToken();
    }
    return projects;
};

export async function getFiles(project, page=1, search="", state="") {
    let data = null;
    let response = null;
    try {
        response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + project.id + "/files", {
            headers: {
                "Authorization": "Bearer " + token(),
            },
            params: {
                search: search,
                page: page,
                state: state
            },
        });
    } catch (error) {
        handleError(error);
    }
    if (response.status === 200){
        data = response.data;
    }
    return data;
}

export async function uploadFile(project, file) {
    const formData = new FormData();
    let response = null;
    formData.append("file", file);
    try {
        response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + project.id + "/files", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + token(),
            },
    });
    } catch (error) {
        response = error.response;
        handleError(error);
    }
    if (response.status === 422){
        setError("Die Datei ist bereits hochgeladen.")
    }
    return response
}

export async function deleteFile(project, file) {
    let response = null;
    try {
        response = await axios.delete(process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + project.id + "/files/" + file.id, {
            headers: {
                "Authorization": "Bearer " + token(),
            },
    });
    } catch (error) {
        response = error.response;
        handleError(error);
    }
    return response
}

export async function downloadFile(project_id, file_id) {
    let response = null;
    try {
        response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + project_id + "/files/" + file_id + "/download", {
            headers: {
                "Authorization": "Bearer " + token(),
            },
            responseType: "blob",
        });
    } catch (error) {
        response = error.response;
        handleError(error);
    }
    return response
}

export async function getConversations(projectId=null, with_messages=false) {
    if (!projectId) {
        projectId = process.env.REACT_APP_PROJECT_ID
    }
    const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + projectId + '/conversations';
    try {
        const response = await axios.get(url, {
            params: {
                with_messages: with_messages
            },
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
        const conversations = response.data.map(parseConversation);
        if (conversations === undefined || conversations === null) { 
            setError('Authentifizierung fehlgeschlagen, das angegebene Token ist nicht gültig.');
            removeToken();
        }
        return conversations;
    } catch (error) {
        handleError(error);

        // Handle error here
    }
}

export async function getConversationById(id, projectId=null) {
    if (!projectId) {
        projectId = process.env.REACT_APP_PROJECT_ID
    }
    const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + projectId + '/conversations/' + id;
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
        const conversation = parseConversation(response.data);
        return conversation;
    } catch (error) {
        handleError(error);

        // Handle error here
    }
}

export async function createConversation(projectId=null) {
    if (!projectId) {
        projectId = process.env.REACT_APP_PROJECT_ID
    }
    const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + projectId + '/conversations';
    try {
        const response = await axios.post(url, {}, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
        const conversation = new Conversation(response.data.id);
        return conversation;
    } catch (error) {
        handleError(error);
        // Handle error here
    }
}

function handleError(error, logoutOn404=false) {
    if (error?.response?.status === 401 || (logoutOn404 && error?.response?.status === 404)) {
        setError('Authentifizierung fehlgeschlagen, das angegebene Token ist nicht gültig. Sie werden in kürze zum Login geleitet.');
        removeToken();
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    } else {
        setError('Der Server ist nicht erreichbar, bitte versuchen Sie es später erneut.');
    }
}

export function round(number, decimals) {
    return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export async function getProject() {
    const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + process.env.REACT_APP_PROJECT_ID;
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
        return response;
    } catch (error) {
        handleError(error);
        return error.response;
        // Handle error here
    }
}

export async function generateDocument(prompt, conversation_id=null, useBetriebsratsBotSources=true, project_ids=[]) {
    if (useBetriebsratsBotSources) {
        project_ids.push(process.env.REACT_APP_PROJECT_ID)
    }
    let response = null;
    try {
        response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + process.env.REACT_APP_TEMPLATE_PROJECT_ID + "/generate_document?provider_first_generation=openai", JSON.stringify({project_ids: project_ids, prompt: prompt, conversation_id: conversation_id}), {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
    } catch (error) {
        handleError(error);
        return error.response;
    }
    return response;
}


export async function downloadAsDocx(conversationId, message_request_id, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + process.env.REACT_APP_TEMPLATE_PROJECT_ID + "/conversations/" + conversationId + "/message_requests/" + message_request_id + "/generate_docx", {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            },
            responseType: 'blob'
        });
    } catch (error) {
        handleError(error);

        return error.response;
    }
    return response;
}

export function reduceToShortText(text, length=30) {
    if (text.length > length) {
      return text.substring(0, length) + '...';
    }
    return text;
}

export function parseDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Tag (immer 2-stellig)
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Monat (0-basiert, daher +1)
    const year = date.getFullYear(); // Jahr

    // Das Ergebnis im Format TT.MM.JJJJ
    return `${day}.${month}.${year}`;
}

export function ratingChanged(newRating, messageID, projectId = null)  {
    projectId = projectId ? projectId : process.env.REACT_APP_PROJECT_ID
    const json = JSON.stringify({rating: newRating})
    const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + projectId + '/chat/' + messageID;
    axios.patch(url, json, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token(),
        }
    })
}

export function clearError() {
    const errorHeader = document.getElementById('error-header');
    errorHeader.style.display = 'none';
}

export function setError(message, timeout = 0) {
    const errorHeader = document.getElementById('error-header');
    errorHeader.style.display = 'block';
    errorHeader.innerHTML = `
        <span>${message}</span>
        <button id="close-error" style="cursor: pointer;">x</button>
    `;

    const closeButton = document.getElementById('close-error');
    closeButton.addEventListener('click', clearError);
    if (timeout > 0) {
        setTimeout(clearError, timeout);
    }
}

export function setSuccess(message, timeout = 0) {
    const successHeader = document.getElementById('success-header');
    successHeader.style.display = 'block';
    successHeader.innerHTML = `
        <span>${message}</span>
        <button id="close-success" style="cursor: pointer;">x</button>
    `;

    const closeButton = document.getElementById('close-success');
    closeButton.addEventListener('click', clearSuccess);
    if (timeout > 0) {
        setTimeout(clearSuccess, timeout);
    }
}

export function clearSuccess() {
    const successHeader = document.getElementById('success-header');
    successHeader.style.display = 'none';
}