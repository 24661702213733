
import { LiaTrashSolid } from "react-icons/lia";
import Popup from 'reactjs-popup';

import { deleteMember } from '../../utils/utils';
import DangerModal from "../../utils/dangerModal";

export default function Member({member, fetchMembers}) {


    async function removeMember() {
        await deleteMember(member);
        await fetchMembers();
    }

    return (
        <tr key={member.id} className='teams-page__members-table-row'>
            <td className='teams-page__members-table-cell teams-page__members-table-cell--starting'>{member.email}</td>
            <td className='teams-page__members-table-cell teams-page__members-table-cell--center'>{member.status}</td>
            <Popup className="modal" trigger={<td className='teams-page__members-table-cell'><LiaTrashSolid style={{cursor: "pointer"}}/></td>} modal>
                {close =>
                    <DangerModal close={close} callback={removeMember} text={"Bist du sicher, dass du das Mitglied entfernen willst?"} title={"Mitglied entfernen"}/>
                }
            </Popup>
        </tr>
    )
}