import React, { useRef } from "react";

function FileUploadButton({ handleFileUpload, fileTypes }) {
  const fileInputRef = useRef(null);
  const formattedFileTypes = fileTypes.map((type) => `.${type.toLowerCase()}`); // Convert to lowercase & prefix with "."

  const onFileSelect = (event) => {
    handleFileUpload(event.target.files);
  };

  const openFileExplorer = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <input
        type="file"
        multiple
        ref={fileInputRef}
        style={{ display: "none" }}
        accept={formattedFileTypes.join(",")}
        onChange={onFileSelect}
      />

      <button onClick={openFileExplorer} className="file-upload-button">
        + Datei hochladen
      </button>
    </div>
  );
};

export default FileUploadButton;
