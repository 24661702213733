import React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as Sentry from "@sentry/react";
import ChatPrompt from './chatPrompt';
import DropdownMenu from '../dropDownMenu';
import ChatReply from './chatReply';
import { TfiPencilAlt } from "react-icons/tfi";
import { LuInfo } from "react-icons/lu";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";

import { getProjects, reduceToShortText, createConversation, cacheConversation, getConversations, getConversationById, disclaimer } from '../../utils/utils';

function Chat({ role, authenticated, logout, setAuthenticated }) {
    const standardProject = { id: process.env.REACT_APP_PROJECT_ID, name: "Betriebratsbot" };
    const [chatFull, setChatFull] = useState(false);
    const [comment, setComment] = useState('');
    const [rating, setRating] = useState(0);
    const [chevron, setChevron] = useState('down');
    const [callToAction, setCallToAction] = useState(true);
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [showSelect, setShowSelect] = useState(false);
    const [conversations, setConversations] = useState([]);
    const [conversation, setConversation] = useState();
    const [chatV2, setChatV2] = useState(false);
    const [state, setState] = useState();
    const [confidenceScore, setConfidenceScore] = useState(-1)
    const [project, setProject] = useState(standardProject);
    const [projects, setProjects] = useState([standardProject]);
    const [chevronUp, setChevronUp] = useState(false);

    const canComment = (project && (project.id === standardProject.id))

    useEffect(() => {
        getProjects().then((fetchedProjects) => {
            let isAuthor = false;
            let ownFiles = null;
            for (let fetchedProject of fetchedProjects) {
                if (fetchedProject.id === standardProject.id) {
                    isAuthor = !!project.write_access
                } else if (!!fetchedProject.write_access) {
                    ownFiles = fetchedProject;
                }
            }
            if (ownFiles) {
                setProjects([standardProject, { id: ownFiles.id, name: "Eigene Dokumente" }]);
            } else if(isAuthor) {
                setProjects([standardProject]);
            } else {
                Sentry.captureException("Not author but no own Project:", { extra: { projects: fetchedProjects } });
            }
        });
    }, []);

    useEffect(() => {
        if (!conversation) {
            newConversation();
        }
    }, []);

    useEffect(() => {
        if (authenticated) {
            fetchConversations();
        }
    }, [authenticated, role]);


    const messagesEndRef = useRef();

    const showConversationCount = 10
    function select() {
        setChevronUp(!chevronUp)
    }

    function selectProject(selectedProject) {
        if (project.id !== selectedProject.id) {
            setConversation(null);
            setConversations([]);
            setProject(selectedProject);
        }
        setChevronUp(false);
    }

    const size = 1.4;

    async function fetchConversations(onError) {
        if (!role  || (role !== "admin" && role !== "guest")) {
            setConversations([]);
            return;
        }
        getConversations(project.id).then((conversations) => {
          setConversations(conversations);
        }).catch(() => {
          onError();
          alert('Dein Token ist abgelaufen, nicht korrekt oder der Server ist gerade nicht erreichbar, bitte logge dich später erneut ein')
        });
    }

    useEffect( () => {
        if (authenticated) {
            const fetchConversation = async () => {
                const updatedConversation = await getConversationById(conversation.id, project.id);
                if (updatedConversation) {
                    cacheConversation(updatedConversation);
                    setConversation(updatedConversation);
                }
            };
            const loadConversation = async () => {
                if (conversation?.id) {
                    await fetchConversation();
                } else {
                    await newConversation();
                }
                await fetchConversations(logout);
                }
            loadConversation();
        }
    }, [authenticated, project]);

    async function selectConversation(e) {
        setShowCommentBox(false);
        const conversation = await getConversationById(e.target.dataset.value, project.id);
        if (conversation) {
            cacheConversation(conversation);
            setConversation(conversation);
        }
    }
    
    async function newConversation() {
        const conversation = await createConversation(project.id);
        if (conversation) {
            cacheConversation(conversation);
            setConversation(conversation);
        }
    }

    const newChat = () => {
        newConversation();
        setCallToAction(true);
        setShowCommentBox(false);
        setChatFull(false);
        setComment('Dein Feedback ...');
        setRating(0);
    }

    function openSelect(e) {
        if (showSelect) {
          closeSelect();
          return
        }
        e.stopPropagation();
        e.preventDefault();
        setChevron('up');
        setShowSelect(true);
        document.addEventListener('click', closeSelect);
    }
    
    const closeSelect = () => {
        setChevron('down');
        setShowSelect(false);
        document.removeEventListener('click', closeSelect);
    }

    const conversationsToShow = () => {
        if (!conversations) {
            return [];
        }
        else if (conversations.length > showConversationCount) {
            // sort by createdAt date, newest first
            const sortedConversations = conversations.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            return sortedConversations.slice(0, showConversationCount);
        }
        return conversations;
    }

    return (
        <div className='Chat'>
            <div className='Chat-action-buttons'>
                <button className='new-chat-button' onClick={newChat}>Neuer Chat <TfiPencilAlt /></button>
                {projects && projects.length > 1 && <DropdownMenu items={projects} selectItem={selectProject} > 
                    <div className="filesPage__status" onClick={select}>{project.name} 
                        {
                            chevronUp ?
                                <IoChevronUpOutline size={size * 12}/>
                            :
                            <IoChevronDownOutline size={size * 12}/>
                        }
                    </div>
                </DropdownMenu>}
                {conversations && conversations.length > 0 && <div className='conversation-select'>
                    {showSelect && <ul className='conversation-list'>
                        {conversationsToShow().map((conv) => (
                            <li className="conversation" key={conv.id} data-value={conv.id} onClick={selectConversation}>
                                {reduceToShortText(conv.title)}
                            </li>
                        ))}
                    </ul>}
                     <span className='conversation-select-button' onClick={openSelect}>
                        Chatverlauf <div className={'chevron ml-5-em chevron-' + chevron}></div>
                    </span>
                </div>}
            </div>
            <ChatReply canComment={canComment} role={role} state={state} confidenceScore={confidenceScore} messagesEndRef={messagesEndRef} rating={rating} setRating={setRating} setComment={setComment} comment={comment} conversation={conversation} setCallToAction={setCallToAction} callToAction={callToAction} setShowCommentBox={setShowCommentBox} showCommentBox={showCommentBox}/>
            {conversation && <ChatPrompt projectId={project.id} setChatV2={setChatV2} role={role} setConfidenceScore={setConfidenceScore} setState={setState} setAuthenticated={setAuthenticated} setRating={setRating} setComment={setComment} conversation={conversation} setConversation={setConversation} setCallToAction={setCallToAction} setShowCommentBox={setShowCommentBox} chatFull={chatFull} setChatFull={setChatFull} fetchConversations={fetchConversations} chatV2={chatV2} messagesEndRef={messagesEndRef}/>}
            <div className='Chat-disclaimer'>{disclaimer()}</div>
            <div className='tooltip info-icon'>
                <LuInfo size={25} color='green' />
                <span className="tooltiptext">Ich bin ein KI Bot.
                    Ich helfe Betriebsräten dabei, schnell Antworten auf Fragen zu finden, die in ihrem Alltag auftreten.
                    Du kannst mir Fragen zur Betriebsratsarbeit stellen und ich versuche die bestmögliche Antwort zu geben.
                    Damit ich besser werden kann, wäre es toll, wenn du mir für jede Frage ein Feedback gibst.
                    Ich speichere die Chatverläufe, um daraus zu lernen.
                </span>
            </div>
        </div>
    );
}

export default Chat;